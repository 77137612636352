import React, { createRef, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useMobXStore } from "../../hooks/useMobXStore";
import "./VariableViewer.scss";
import { useNavigate, useParams } from "react-router-dom";
import VariableDetailsView from "../../components/VariableDetailsView/VariableDetailsView";
import _ from "lodash";
import validateVariable from "../../helpers/validationHelper";
import ConfirmationDialog from "../../components/VariableDetailsEdit/ConfirmationDialog/ConfirmationDialog";
import { TdsBanner, TdsIcon } from "@scania/tegel-react";
import { VARIABLE_UPDATE_MODES } from "../../helpers/constants";

const VariableViewer = () => {
  let { id } = useParams();
  const { variableDetailsStore } = useMobXStore()

  const { selectedVariableVariantVersion, selectedVariableDetails, isLoadingVariableDetails, variableFetchError, selectVariableVariantVersionForViewing } = variableDetailsStore

  const [variableStatus, setVariableStatus] = useState('');
  const navigate = useNavigate();
  const modalDialogRef = createRef<HTMLTdsModalElement>()
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showValidationModal, setShowValidationModal] = useState<boolean>(false);
  const [invalidFields, setInvalidFields] = useState<Array<string>>([]);


  const clearSelectedVariable = () => {
    variableDetailsStore.clearSelectVariable();
    navigate("/", { replace: false })
  }

  const navigateToVariableEditpage = () => {
    navigate(`/${selectedVariableVariantVersion.id}/edit`, { replace: false })
  }

  const handleUpdateVariableDetails = (variableDetails: VariableBase) => {
    if (variableDetails.id) {
      variableDetailsStore.updateVariableVariantVersionDetails({ id: variableDetails.id, variant_name: '', version_number: '' } as SelectedVariableVariantVersion, variableDetails);
    }
  }

  const handleConfirmationStatuschange = () => {
    setShowConfirmationModal(false)
    const invalidFields = validateVariable(selectedVariableDetails)
    if (invalidFields && invalidFields.length > 0) {
      setInvalidFields(invalidFields);
      setShowValidationModal(true)
    } else {
      const updatedVariableData = { ...selectedVariableDetails, status: variableStatus } as VariableBase;

      variableDetailsStore.setSelectedVariableDetails(updatedVariableData);

      if (updatedVariableData.type === 'class') {
        if (updatedVariableData.boundaries && updatedVariableData.boundaries instanceof String) {
          updatedVariableData.boundaries = updatedVariableData.boundaries.split(',').map((item: string) => parseFloat(item.trim()));
        }
      }
      if (updatedVariableData.value_names) {
        updatedVariableData.value_names = _.chain(updatedVariableData.value_names)
          .keyBy('hexCode')
          .mapValues('name')
          .value();
      }
      handleUpdateVariableDetails(updatedVariableData);
    }
  }

  const detectUserAction = (userAction: string) => {
    if (["Edit", "Update"].includes(userAction)) {
      variableDetailsStore.setIsVariableUpdateMode(false);
      navigateToVariableEditpage();
    }
    else if ([VARIABLE_UPDATE_MODES.WithoutVersion, VARIABLE_UPDATE_MODES.WithVersion].includes(userAction)) {
      variableDetailsStore.setVariableUpdateMode(userAction)
      variableDetailsStore.setIsVariableUpdateMode(true);
      navigateToVariableEditpage();
    }

    if (["Published", "Deprecated", "Verified"].includes(userAction)) {
      setVariableStatus(userAction);
      setShowConfirmationModal(true)
      // reset()
    }
  };
  const onValidationModalClose = () => {
    setShowValidationModal(false);
    setVariableStatus("");
  }
  const onCancel = () => {
    setShowConfirmationModal(false)
  }
  useEffect(() => {
     if (id && parseFloat(id) !== selectedVariableVariantVersion.id) {
      selectVariableVariantVersionForViewing(parseFloat(id),selectedVariableVariantVersion?.variant_name, selectedVariableVariantVersion?.version_number);
    } 
  }, [id, selectVariableVariantVersionForViewing, selectedVariableVariantVersion])


  return (
    <>
      {!isLoadingVariableDetails && variableFetchError !== "" &&
        <TdsBanner variant="error" header={variableFetchError} subheader={`The variable with Id: ${selectedVariableVariantVersion.id} not found in the database.`}>
        </TdsBanner>
      }
      {(selectedVariableVariantVersion.id >= 0 && !variableFetchError) &&
        <VariableDetailsView
          selectedVariableBaseDetails={selectedVariableDetails}
          clearSelectedVariable={clearSelectedVariable}
          isLoadingVariableDetails={isLoadingVariableDetails}
          detectUserAction={detectUserAction}
        />
      }
      {showConfirmationModal && <ConfirmationDialog
        header="Confirm Variable status change"
        ref={modalDialogRef}
        onCancel={onCancel}
        onConfirm={handleConfirmationStatuschange}
      >
        <>
          <p className="tds-body-01">Are you sure you want to change the status of the variable to <strong>{variableStatus}</strong>.</p>
          <p className="tds-body-02">
            <strong>NOTE </strong>: Once confirmed, the status could not be reverted back
          </p>
        </>
      </ConfirmationDialog>}

      {showValidationModal && <ConfirmationDialog
        header=""
        ref={modalDialogRef}
        onCancel={onCancel}
        onConfirm={onValidationModalClose}
        confirmOnly={true}
        confirmButtonTitle="Ok"
      >
        <>
          <span className="tds-text-red-500" slot="header">
            <TdsIcon name="error" size="32" /> Validation failed!
          </span>
          <p className="tds-body-01">
            Following fields are required to be able to perfom this action.</p>
          <p className="tds-body-02">
            <ul>
              {invalidFields && invalidFields.map((field) => {
                return <li><strong>{field}</strong></li>
              })}
            </ul>
          </p>
        </>
      </ConfirmationDialog>}
    </>
  );
};

export default observer(VariableViewer);
