
import { TdsTextField } from '@scania/tegel-react';
import { observer } from 'mobx-react';
import './HistogramView.scss';
interface HistogramViewProps {
    variableData?: VariableVariantVersionDetail
}
const HistogramView = ({ variableData }: HistogramViewProps) => {

    return (<>
        <div className='tds-row tds-u-mt3'>
            <div className='tds-col-sm-6'>
                <TdsTextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    label="Unit *"
                    label-position="outside"
                    value={variableData?.unit}
                    placeholder='Enter unit of measurement'
                    readOnly={true} />

            </div>
            <div className='tds-col-sm-6'>
                {/* TODO We need to check with API  team persist is nullable or not, If nullable we need to change value related logic according to requirement variableData*/}
                <TdsTextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    label="Persist"
                    labelPosition="outside"
                    readOnly={true}
                    value={variableData?.persist === true ? "True" : "False"}>
                </TdsTextField>
            </div>
        </div>
        <div className='tds-row tds-u-mt3'>
            <div className='tds-col-sm-12 '>
                <TdsTextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    label="Boundaries *"
                    label-position="outside"
                    placeholder='Please enter your boundary values separated by commas. E.g. -1.1, -0.9, -0.7, -0.5, -0.3, -0.1, 0.1, 0.3, 0.5, 0.7, 0.9, 1.1'
                    readOnly={true}
                    value={JSON.stringify(variableData?.boundaries)}
                />
            </div>
        </div>
    </>
    );
};

export default observer(HistogramView);