import { TdsBanner } from "@scania/tegel-react";
import { ValidationErrors } from "final-form";
import arrayMutators from 'final-form-arrays';
import _ from "lodash";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationDialog from "../../components/VariableDetailsEdit/ConfirmationDialog/ConfirmationDialog";
import VariableDetails from "../../components/VariableDetailsEdit/VariableDetails";
import { newBaseVariable } from "../../helpers/dataHelper";
import { updateVersion } from "../../helpers/updateVersion";
import validateVariable, { validateRequiredAttributes } from "../../helpers/validationHelper";
import { useMobXStore } from "../../hooks/useMobXStore";
import { VARIABLE_STATUS, VARIABLE_UPDATE_MODES } from "../../helpers/constants";

//Styles
import "./VariableEditor.scss";


const VariableEditor = ({ isCreateMode }: { isCreateMode?: boolean }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  // const { reset, setValue } = methods;
  const { variableDetailsStore } = useMobXStore()

  const { selectedVariableVariantVersion, selectedVariableDetails, variableUpdateMode, isLoadingVariableDetails, isSaveSuccessfull, variableFetchError } = variableDetailsStore

  const [variableStatus, setVariableStatus] = useState<string>('');
  const [validVariableData, setValidVariableData] = useState<VariableVariantVersionDetail>();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const clearSelectedVariable = () => {
    variableDetailsStore.clearSelectVariable();
  }

  const handleCreateVariableDetails = (variableDetails: VariableVariantVersionDetail) => {
    variableDetailsStore.resetSaveSuccessFlag();
    variableDetailsStore.createVariableVariantVersionDetails(variableDetails);
  };

  const handleUpdateVariableDetails = (variableDetails: VariableVariantVersionDetail) => {
    variableDetailsStore.resetSaveSuccessFlag();
    if (variableDetails.id) {
      variableDetailsStore.updateVariableVariantVersionDetails(variableDetails.id, variableDetails);
    }
  }

  const onSubmit = (data: VariableVariantVersionDetail) => {
    if (data.type === 'class') {
      if (data.boundaries && data.boundaries instanceof String) {
        data.boundaries = data.boundaries.split(',').map((item: string) => parseFloat(item.trim()));
      }
    }
    setValidVariableData(data);
    if (!['Created', 'Save'].includes(variableStatus)) {
      setShowConfirmationDialog(true);
    } else {
      executeUserAction(variableStatus, data);
    }
    //executeUserAction(variableStatus, validVariableData);
  };

  const detectUserAction = (userAction: string) => {
    setVariableStatus(userAction)
  };

  const executeUserAction = (variableStatus: string, data: VariableVariantVersionDetail) => {
    if ((variableStatus === 'Created' && data?.name && data?.id === undefined)) {
      const updatedVariableData = { ...data, status: variableStatus as VariableStatusType };
      handleCreateVariableDetails(updatedVariableData) // check and call the right method after integrating update flow
    }
    else if (variableStatus === 'Save') {
      if (variableUpdateMode === VARIABLE_UPDATE_MODES.WithVersion) {
        //{/* TODO: This is for temparary to remove varient data: Need to be implement later */}
        //TODO: Temparary to set variants as [] 
        const updatedVariableData = { ...data, status: "Created" as VariableStatusType, id: undefined, version: updateVersion(data.version), variants: [] };
        handleCreateVariableDetails(updatedVariableData)
      } else {
        handleUpdateVariableDetails(data)
      }
    }
    else {
      const updatedVariableData = { ...data, status: variableStatus as VariableStatusType };
      handleUpdateVariableDetails(updatedVariableData)
    }
  }

  const onCancel = () => {
    setShowConfirmationDialog(false);
  }

  const handleConfirmationStatuschange = () => {
    validVariableData && executeUserAction(variableStatus, validVariableData);
    setShowConfirmationDialog(false);
  }


  useEffect(() => {
    if (isCreateMode) {
      variableDetailsStore.activateCreateMode();
    }
    else {
      if (id) {
        const idParam = parseFloat(id);
        if (idParam !== variableDetailsStore.selectedVariableVariantVersion.id) {
          // todo:  need to fetch the variant_name and version_number before saving it in the store. 
          variableDetailsStore.selectVariableVariantVersionForViewing(idParam, '', '');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [id, isCreateMode]);

  useEffect(() => {
    //Reset the form with new default values whenever selectedVariableDetails changes
    // initialize(selectedVariableDetails);
    if (!selectedVariableDetails.chain || selectedVariableDetails.chain.length === 0) {
      // setValue("chain", [{ type: "input" } as ChainItemType])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedVariableDetails]);

  useEffect(() => {
    if (selectedVariableVariantVersion.id === selectedVariableDetails.id && !isCreateMode) {
      if (selectedVariableDetails.status === VARIABLE_STATUS.PUBLISHED && variableUpdateMode === '') {
        navigate(`/${selectedVariableVariantVersion.id}/view`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedVariableDetails, navigate, variableUpdateMode, selectedVariableVariantVersion]);

  useEffect(() => {
    if (isSaveSuccessfull) {
      variableDetailsStore.resetSaveSuccessFlag();
      navigate(`/${selectedVariableVariantVersion.id}/view`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveSuccessfull]);

  const onFormValidate = (values: Record<string, any>) => {
    const validationErrors: ValidationErrors = {};
    const mustHaveFileds = ["type", "name"]
    if (!["Created", 'Save'].includes(variableStatus) &&
      (selectedVariableDetails.status && !["", "Created"].includes(selectedVariableDetails.status))) {
      //validate Values here 
      const invalidFields = validateVariable(values as VariableBase);
      invalidFields.forEach((fieldName) => {
        validationErrors[fieldName] = "This field is required!"
      })
    } else {
      const invalidFields = validateRequiredAttributes(values as VariableBase, mustHaveFileds);
      invalidFields.forEach((fieldName) => {
        validationErrors[fieldName] = `Variable ${fieldName.toLowerCase()} is required!`
      })
    }

    return validationErrors
  }
  return (
    <>
      {!isLoadingVariableDetails && variableFetchError !== "" &&
        <TdsBanner variant="error" header={variableFetchError} subheader={`The variable with Id: ${selectedVariableVariantVersion.id} not found in the database.`}>
        </TdsBanner>
      }
      {((!isLoadingVariableDetails && variableFetchError === "" && selectedVariableVariantVersion.id >= 0) || isCreateMode) &&
        <Form
          mutators={{
            // potentially other mutators could be merged here
            ...arrayMutators
          }}
          subscription={{ submitting: true, pristine: true, invalid: true }}
          onSubmit={onSubmit}
          validate={onFormValidate}
          // TODO:  the intial values should be a flatter object structure to make the react final form hook work propoerly
          initialValues={selectedVariableDetails || newBaseVariable}
          keepDirtyOnReinitialize={false}
          render={({ handleSubmit, hasValidationErrors, errors }) => {
            return <form onSubmit={handleSubmit}>
              {hasValidationErrors && errors && <div>
                {_.keys(errors).map((key: string) => {
                  return <span>{errors[key]}</span>
                })}
              </div>}
              <VariableDetails
                selectedVariableBaseDetails={selectedVariableDetails}
                clearSelectedVariable={clearSelectedVariable}
                isLoadingVariableDetails={isLoadingVariableDetails}
                detectUserAction={detectUserAction}
                isVariableUpdateMode={variableDetailsStore.isVariableUpdateMode}
                variableUpdateMode={variableDetailsStore.variableUpdateMode}
              />
            </form>
          }} />
      }
      {showConfirmationDialog &&
        <ConfirmationDialog
          header="Confirm Variable status change"
          onCancel={onCancel}
          onConfirm={handleConfirmationStatuschange}
        >
          <>
            <p className="tds-body-01">Are you sure you want to change the status of the variable to <strong>{variableStatus}</strong>.</p>
            <p className="tds-body-02">
              <strong>NOTE </strong>: Once confirmed, the status could not be reverted back
            </p>
          </>
        </ConfirmationDialog>
      }
    </>
  );
};

export default observer(VariableEditor);
