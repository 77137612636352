import { TdsButton,  TdsIcon } from "@scania/tegel-react"
import { observer } from "mobx-react"
import { useLocation, useNavigate } from "react-router-dom"
import VariableVersionIcon from "../../VariableVersionIcon/VariableVersionIcon"
import "./TopSection.scss"

type TopSectionProps = {
  selectedVariableId: number
  selectedVariableDetails?: VariableBase
  clearSelectedVariable: () => void
  onUserAction: (userAction: string) => void
  reset?: () => void
}

const TopSection = ({ selectedVariableId, selectedVariableDetails, clearSelectedVariable, onUserAction, reset }: TopSectionProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isEditMode = pathname.toLowerCase().endsWith('edit');


  const returnToReadOnly = () => {
    reset && reset();
    navigate(`/base/${selectedVariableId}/view`, { replace: false })
  }

  const onCloseClick = () => {

    reset && reset();
    clearSelectedVariable()
    navigate('/', { replace: false })
  }

  return (
    <>
      <div>
        <div className="row">
          <span className="tds-u-gap2 left-aligned">
            <h5 className="blue-400">ID : {selectedVariableDetails?.id}</h5>
            <VariableVersionIcon  bgCssClass="bg-grey-868" version="Base"  />
          </span>
          <TdsButton
            type="button"
            className="right-aligned"
            variant="ghost"
            size="sm"
            onClick={onCloseClick}
          >
            <TdsIcon slot="icon" size="20px" name="cross" />
          </TdsButton>
        </div>
        <h3 className="tds-u-mt1 tds-u-mb1"> {selectedVariableDetails?.name} </h3>

        <div className="row">
          <p>Last modified by : <span className="tds-text-blue-400">
              {selectedVariableDetails?.modifiedBy ? selectedVariableDetails?.modifiedBy : <span title="Created by Initial Data Import.">System</span> }
            </span>
          </p>
          <div className="action-buttons right-aligned">
            <span className="tds-u-gap2 tds-row">
              {!isEditMode ? <> {/* Is Readonly or View Mode */}
                <TdsButton variant="secondary" text="Edit " size="sm" onClick={() => { onUserAction('Edit') }} />
                {/* TODO: This is for temparary to remove varient data: Need to be implement later */} 
                {/* <TdsButton variant="secondary" text="Add Variant" type="submit"  size="sm" onClick={() => { onUserAction('AddVariant'); }}>
                  <TdsIcon name="plus" slot="icon" />
                  </TdsButton>  */}
              </> : <> {/* Is In Edit Mode */}
                <TdsButton variant="secondary" type="button" text="Cancel" size="sm" onClick={() => { returnToReadOnly(); }} />
                <TdsButton variant="primary"   type="submit" text="Save" size="sm" onClick={() => { onUserAction('Save'); }} />
              </>}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(TopSection)