import VariableVersionIcon from "../VariableVersionIcon/VariableVersionIcon"
import "./TopSection.scss"
import { TdsButton, TdsIcon } from "@scania/tegel-react"
import { useLocation, useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import TopSectionButtons from "./TopSectionButtons"

type TopSectionProps = {
  selectedVariableBaseDetails: VariableBase | undefined
  clearSelectedVariable: () => void
  onUserAction: (userAction: string) => void
  reset?: () => void
}

const TopSection = ({ selectedVariableBaseDetails, clearSelectedVariable, onUserAction, reset }: TopSectionProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isCreateMode = pathname.toLowerCase().endsWith('create');

  const returnToReadOnly = () => {
    reset && reset();
    navigate(`/${selectedVariableBaseDetails?.id}/view`, { replace: false })
  }

  const onCloseClick = () => {

    reset && reset();
    clearSelectedVariable()
    navigate('/', { replace: false })
  }

  return (
    <>
      {!isCreateMode && (
        <div>
          <div className="row">
            <span className="tds-u-gap2 left-aligned">
              {/*  TODO: the ID seems to be pointless here since many variants and their version will havwe the same ID of the base variable  */}
              <h5 className="blue-400">
                {" "}
                ID : {selectedVariableBaseDetails?.id}{" "}
              </h5>
              {/* TODO: figure out to best possibly get the variable version to display */}
              <VariableVersionIcon
                version={`v${selectedVariableBaseDetails?.variant_version?.version_number}`}
              />
              <h5 className="blue-400">
                {" "}
                {/* TODO : make sure the status is correctly fetched */}
                Status : {selectedVariableBaseDetails?.variant_version?.status}{" "}
              </h5>
            </span>
            <TdsButton
              type="button"
              className="right-aligned"
              variant="ghost"
              size="sm"
              onClick={onCloseClick}
            >
              <TdsIcon slot="icon" size="20px" name="cross" />
            </TdsButton>
          </div>
          <h3> {selectedVariableBaseDetails?.name} </h3>

          <div className="row">
            <p>
              Last modified by{" "}
              <span className="blue-400">
                {selectedVariableBaseDetails?.modified?.username} {selectedVariableBaseDetails?.modified?.timestamp}
              </span>
            </p>
            <div className="action-buttons right-aligned">
              {/* TODO : make sure the status is correctly fetched */}
              <TopSectionButtons variableStatus={selectedVariableBaseDetails?.variant_version?.status} onUserAction={onUserAction} returnToReadOnly={returnToReadOnly} onCloseClick={onCloseClick} />

            </div>
          </div>
        </div>
      )}
      {isCreateMode && (
        <div className="row tds-u-mb2">
          <h3> Create Variable </h3>
          <div className="action-buttons right-aligned">
            <span className="tds-u-gap2 tds-row">
              <TdsButton
                variant="secondary"
                text="Cancel"
                size="sm"
                onClick={onCloseClick}
              />
              <TdsButton
                type="submit"
                variant="primary"
                text="Save"
                size="sm"
                onClick={() => onUserAction("Created")}
              />
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(TopSection)