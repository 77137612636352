import React from 'react';
import { TdsBodyCell, TdsTable, TdsTableBody, TdsTableBodyRow, TdsTableBodyRowExpandable, TdsTableHeader } from '@scania/tegel-react';
import VariableTypeIcon from '../../VariableTypeIcon/VariableTypeIcon';
import VariableVersionIcon from '../../VariableVersionIcon/VariableVersionIcon';

type ViewDefinitionRowProps = {
    index: number
    id: number
    name: string
    type?: string
    showDetails: (isBase: boolean, id?:number, variant?: any ) => void
    variants: Array<VariantInfo>
    //isLoading: boolean;
    //selectedItem: number | null
    // description?: string,
    // status?: string
}
const VariableListRow = (props: ViewDefinitionRowProps) => {
    const { id, index, showDetails, name, type, variants } = props;
    return (
        <TdsTableBodyRowExpandable rowId={`${index}`} colSpan={3} >

            <TdsBodyCell cellKey="baseName" className='name-column' title={name}>{name}</TdsBodyCell>
            {/* The icon needs to be changed based on the type when received from the API  */}
            <TdsBodyCell cellKey="baseType" className='type-column'>
                {type && type?.toLowerCase() === "gauge" && <VariableTypeIcon type='G' tooltipText="Gauge" key={`G-${id}`}></VariableTypeIcon>}
                {type && type?.toLowerCase() === "class" && <VariableTypeIcon type='H' tooltipText="Histogram" key={`H-${id}`}></VariableTypeIcon>}
                {type && type?.toLowerCase() === "time_series" && <VariableTypeIcon type='Ts' tooltipText="Time Series" key={`Ts-${id}`}></VariableTypeIcon>}
                {type && type?.toLowerCase() === "trigger" && <VariableTypeIcon type='Tr' tooltipText="Trigger" key={`Tr-${id}`}></VariableTypeIcon>}
                {!type && <VariableTypeIcon type='-' tooltipText="Type is not available" key={`NA-${id}`}></VariableTypeIcon>}

            </TdsBodyCell>
            {variants && variants.length > 0 && <div slot="expand-row" className='expanded-row'>
                <TdsTable noMinWidth responsive>
                    <TdsTableHeader style={{display: 'none'}}/>
                    <TdsTableBody>
                        <TdsTableBodyRow onClick={() => showDetails(true, id)}>
                            <TdsBodyCell cellKey="variantName" className='name-col tds-u-pl1' title={name}>{name}</TdsBodyCell>
                            <TdsBodyCell cellKey="variantVersion" className='version-col'>
                                <VariableVersionIcon version="Base" bgCssClass="bg-grey-868" ></VariableVersionIcon>
                            </TdsBodyCell>
                        </TdsTableBodyRow>
                        {variants.map((variant) => {
                            return <TdsTableBodyRow onClick={() => showDetails(false, variant.id, variant)} key={"" + variant.id + variant.version}>
                                    <TdsBodyCell cellKey="variantName" className='name-col tds-u-pl1' title={name}>{name + "@" + variant.variant_name}</TdsBodyCell>
                                    <TdsBodyCell cellKey="variantVersion" className='version-col'>
                                        <VariableVersionIcon version={variant.version}></VariableVersionIcon>
                                    </TdsBodyCell>
                            </TdsTableBodyRow>
                        })}
                    </TdsTableBody>
                </TdsTable>
            </div>}
        </TdsTableBodyRowExpandable>
    )
}
export default VariableListRow;