
const variableTypesList = [
    { name: "Gauge", value: "gauge" },
    { name: "Histogram (Class)", value: "class" },
    { name: "Trigger", value: "trigger" },
    { name: "Time series", value: "time_series" }
]

const modelDropdownList = [
    { name: "BOTH", value: "BOTH" },
    { name: "HIGH", value: "HIGH" },
    { name: "LOW", value: "LOW" },
    { name: "ON_CHANGE", value: "ON_CHANGE" },
    { name: "TIMER", value: "TIMER" }
]

// {/* TODO We need to check with API  team persist is nullable or not, If nullable we need to change value related logic according to requirement variableData*/}
const persistDropdownList = [
    { name: "False", value: false },
    { name: "True", value: true }
]

const dataactDropdownList = [
    { name: "False", value: "false" },
    { name: "True", value: "true" }
]

const shouldRemoveRedundatSamplesDropdownList = [
    { name: "False", value: "false" },
    { name: "True", value: "true" }
]

const scopeValues = {
    gauge: "101",
    trigger: "102",
    class: "103",
    time_series: "104"
}

const connectivityPlatformDropdownList = [
    { name: "CM4", value: "CM4" },
    { name: "TBM3", value: "TBM3" }
]

export {
    variableTypesList,
    modelDropdownList,
    persistDropdownList,
    shouldRemoveRedundatSamplesDropdownList,
    scopeValues,
    dataactDropdownList,
    connectivityPlatformDropdownList,
}