import { orderBy } from "lodash"
import { observable, action, computed, makeObservable, runInAction } from "mobx"
import { compareVersions } from 'compare-versions';
import { getVariableList } from "../adapters/VariableDetailsAdapter"
import { RootStoreType } from ".";

export interface VariableTypeFilter {
  name: string
  iconType: 'G' | 'H' | 'Ts' | 'Tr' | string
  checked: boolean
}
export interface VariableStateFilter {
  name: string
  checked: boolean
}

export class VariableListStore {
  variableList: Array<VariableInfo> = []
  filteredVariableList: Array<VariableInfo> = []
  selectedVariable: VariableInfo | null = null
  searchId: string = ""
  searchName: string = ""
  searchVersion: string = ""

  variableTypesList: Array<VariableTypeFilter> = [{
    name: "gauge",
    iconType: "G",
    checked: true
  }, {
    name: "class",
    iconType: "H",
    checked: true
  }, {
    name: "time_series",
    iconType: "Ts",
    checked: true
  }, {
    name: "trigger",
    iconType: "Tr",
    checked: true
  }];

  prevVariableTypesList: Array<VariableTypeFilter> = this.variableTypesList;

  variableStatesList: Array<VariableStateFilter> = [{
    name: "Created",
    checked: true
  }, {
    name: "Verified",
    checked: true
  }, {
    name: "Published",
    checked: true
  }, {
    name: "Deprecated",
    checked: true
  }];

  prevVariableStatesList: Array<VariableStateFilter> = this.variableStatesList;

  selectedFilterVersion: string = "latest";

  prevSelectedFilterVersion: string = this.selectedFilterVersion;

  isLoadingVariableList: boolean = false
  toastData: ToastData | null = { message: "Test Message", variant: "error" };
  rootStore: RootStoreType | null = null;

  constructor(root: RootStoreType) {
    this.rootStore = root;
    return makeObservable(this, {
      variableList: observable,
      filteredVariableList: observable,
      selectedVariable: observable,
      searchId: observable,
      searchName: observable,
      searchVersion: observable,
      variableTypesList: observable,
      variableStatesList: observable,
      selectedFilterVersion: observable,
      toastData: observable,
      loadVariables: action,
      selectVariableForViewing: action,
      clearSelectVariable: action,
      setSearchId: action,
      setSearchName: action,
      setSearchVersion: action,
      filter: action,
      onChangeVariableTypeFilter: action,
      onChangeVariableStateFilter: action,
      onChangeVersionFilter: action,
      onResetFilters: action,
      onSubmitFilters: action,
      onCancelFilters: action,
      sortedFilteredTableData: computed,
    });
  }

  loadVariables = () => {
    if (!this.isLoadingVariableList) {
      this.isLoadingVariableList = true;
      this.toastData = null;

      getVariableList().then((response) => {
        runInAction(() => {
          this.variableList = response.data;          
          this.onSubmitFilters()
          this.isLoadingVariableList = false;
        })
        //after save, we say saved successfully
        //this.rootStore?.toastStore?.addToast("Loading Successfull!","success");
      }).catch(error => {
        console.error('variable list store  while loading variables:', error);
        this.toastData = { message: error.message, variant: 'error' };
        this.rootStore?.toastStore?.addToast(error.message, "error");
      }).finally(() => {
        this.isLoadingVariableList = false;
      })
    }
  }

  selectVariableForViewing = (variable: VariableInfo) => {
    this.selectedVariable = variable;
  }

  clearSelectVariable = () => {
    this.selectedVariable = null;
  }

  setSearchId = (value: string) => {
    this.searchId = value;
  }

  setSearchName = (value: string) => {
    this.searchName = value;
  }

  setSearchVersion = (value: string) => {
    this.searchVersion = value;
  }

  filter = (variable: VariableInfo) => {
    this.selectedVariable = variable;
  }

  onChangeVariableTypeFilter = (typeName: string | undefined, checked: boolean) => {
    this.variableTypesList = this.variableTypesList.map((item) => item.name === typeName ? { ...item, checked: checked } : item)
  }

  onChangeVariableStateFilter = (typeName: string | undefined, checked: boolean) => {
    this.variableStatesList = this.variableStatesList.map((item) => item.name === typeName ? { ...item, checked: checked } : item)
  }

  onChangeVersionFilter = (filterValue: string) => {
    this.selectedFilterVersion = filterValue
  }

  onCancelFilters = () => {
    this.variableTypesList = this.prevVariableTypesList
    this.variableStatesList = this.prevVariableStatesList
    this.selectedFilterVersion = this.prevSelectedFilterVersion
    this.onSubmitFilters()
  }

  onResetFilters = () => {
    this.variableTypesList = this.prevVariableTypesList = this.variableTypesList.map((item) => { item.checked = true; return item; });
    this.variableStatesList = this.prevVariableStatesList = this.variableStatesList.map((item) => { item.checked = true; return item; });
    this.selectedFilterVersion = this.prevSelectedFilterVersion = "latest";
    this.onSubmitFilters()
  }

  onSubmitFilters = () => {
    if (this.variableList.length === 0) {
      return [];
    }
    // Step 1: Extract selected types and statuses
    const selectedTypes = this.variableTypesList.filter((type) => type.checked).map((type) => type.name);
    const selectedStatuses = this.variableStatesList.filter((status) => status.checked).map((status) => status.name);

    // Step 2: Filter the variable list
    const filteredVariables = this.variableList.map((variable) => {
      // Filter the variants based on selected types and statuses
      const filteredVariants = variable.variants.filter(
        (variant) =>
          (!selectedTypes.length || selectedTypes.includes(variable.type)) &&
          (!selectedStatuses.length || selectedStatuses.includes(variant.status))
      );
      // Return only variables with filtered variants
      return { ...variable, variants: filteredVariants };
    }).filter((variable) => variable.variants.length > 0); // Remove variables with no variants

    // Step 3: Update the filtered variable list and preserve the state
    this.filteredVariableList = filteredVariables;
    this.prevSelectedFilterVersion = this.selectedFilterVersion;
    this.prevVariableStatesList = this.variableStatesList;
    this.prevVariableTypesList = this.variableTypesList
  };

  sortVariablesVariantVersionWithHighestValue = (variables: Array<VariableInfo>) => {
    return variables.map((variable) => {
      // Filter the latest version for each unique variant_name
      const latestVariants = Object.values(
        variable.variants.reduce((acc, variant) => {
          const { variant_name } = variant;
          // Compare versions and keep the latest
          if (!acc[variant_name] || compareVersions(variant.version, acc[variant_name].version) > 0) {
            acc[variant_name] = variant;
          }
          return acc;
        }, {} as Record<string, VariantInfo>)
      );
      // Sort the filtered variants by version in descending order
      const sortedVariants = latestVariants.sort((a, b) =>
        compareVersions(b.version, a.version)
      );
      // Return the updated VariableInfo object with sorted variants
      return {
        ...variable,
        variants: sortedVariants,
      };
    });
  };


  get sortedFilteredTableData() {
    if (!this.filteredVariableList || this.filteredVariableList.length === 0) {
      return []
    }
    // var ids: Array<VariableType> = orderBy(this.filteredVariableList, ["id"], ["asc"]).filter(
    //   (obj: any) => {
    //     let showId = this.searchId.length > 0 ? obj?.id?.toString().indexOf(this.searchId) !== -1 : true;
    //     return showId;
    //   }
    // );

    var variableNames: Array<VariableInfo> = orderBy(this.filteredVariableList, ["name"], ["asc"]).filter((obj: any) => {
      let showVariable = this.searchName.length > 0 ? obj?.name?.toLowerCase().indexOf(this.searchName.toLowerCase()) !== -1 : true;
      return showVariable;
    });

    // var versions: Array<VariableType> = orderBy(this.filteredVariableList, ["version"], ["asc"]).filter((obj: any) => {
    //   let showVersion = this.searchVersion.length > 0 ? obj?.version?.toLowerCase().indexOf(this.searchVersion.toLowerCase()) !== -1 : true;
    //   return showVersion;
    // });

    return variableNames;
    // return intersection(ids, variableNames, versions);
  };
}
