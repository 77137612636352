import React, { ReactNode } from "react";
import { TdsTooltip, TdsIcon } from "@scania/tegel-react";
import { Field, FieldRenderProps } from "react-final-form";
import "./EUDataAct.scss";
import { observer } from "mobx-react";

type ConditionProps = {
  name: string;
  values?: VariableBase;
  title: string;
  description: string;
  tooltip: ReactNode;
  editing: boolean;
};

const RadioField: React.FC<FieldRenderProps<string, HTMLElement>> = ({ input, label }) => {
  return (
    <div className="tds-radio-button sc-tds-radio-button">
      <input {...input} type="radio" className="tds-form-input sc-tds-radio-button" />
      <label htmlFor={input.name} className="sc-tds-radio-button sc-tds-radio-button-s">
        {label}
      </label>
    </div>
  );
};

const calculate_in_scope_for_data_act = (v?: VariableBase): boolean => {
  var offboarded = v?.offboarded === null || v?.offboarded === undefined ? true : !!v?.offboarded;
  var produced_through_usage =
    v?.produced_through_usage === null || v?.produced_through_usage === undefined ? true : !!v?.produced_through_usage;
  var enriched = v?.enriched === null || v?.enriched === undefined ? false : !!v?.enriched;
  var impact_on_safety =
    v?.impact_on_safety === null || v?.impact_on_safety === undefined ? false : !!v?.impact_on_safety;

  return enriched === false && offboarded === true && produced_through_usage === true && impact_on_safety === false;
};

const Condition = ({ name, values, title, description, tooltip, editing }: ConditionProps) => (
  <div className="tds-row">
    <div className="tds-col-sm-8" style={{ marginRight: "10px" }}>
      <div>
        {title}
        <TdsTooltip placement="right-start" selector={`.eudataact .${name}-info-icon`} mouseOverTooltip>
          {tooltip}
        </TdsTooltip>
        <TdsIcon className={`tds-text-blue-500 tds-u-ml1 ${name}-info-icon`} name="info" size="18px"></TdsIcon>
      </div>
      <div className="medium_emphasis" style={{ marginTop: 10, marginBottom: 10 }}>
        {description}
      </div>
    </div>
    {editing ? (
      <>
        <div className="tds-col-sm-1">
          <Field
            name={name}
            component={RadioField}
            type="radio"
            value="true"
            parse={(v: any): any => v === "true"}
            format={(v) => String(v)}
            label="Yes"
          />
        </div>
        <div className="tds-col-sm-2">
          <Field
            name={name}
            component={RadioField}
            type="radio"
            value="false"
            parse={(v: any): any => v === "true"}
            format={(v) => String(v)}
            label="No"
          />
        </div>
      </>
    ) : (
      <div className="tds-col-sm-1">{values?.[name] ? "Yes" : "No"}</div>
    )}
  </div>
);

type EUDataActProps = {
  values?: VariableBase;
  editing?: boolean;
};

const EUDataAct = ({ values, editing }: EUDataActProps) => {
  const is_in_scope_for_data_act = editing ? calculate_in_scope_for_data_act(values) : values?.in_scope_for_data_act;

  return (
    <>
      <h3>EU Data Act</h3>
      <div
        className={`tds-container-fluid tds-background-grey-50 tds-u-p1 tds-u-pt2 tds-u-mt1 tds-u-pb3 read eudataact`}
      >
        <Condition
          name="offboarded"
          editing={editing || false}
          values={values}
          title="Offboarded"
          description="Variable/Signal that is transferred outside the vehicle for storage, processing, or analysis"
          tooltip={
            <>
              <p className="tds-detail-05 tds-u-m0 tooltip-paragraph">
                Offboarded variable/signal refers to data that is transmitted off of the connected product in any way,
                regardless of type of data, method, format, consent, frequency, or reason.
              </p>
              <br />
              <b>Examples of In-Scope</b>
              <ul>
                <li>Examples of types of data: telematic data, diagnostic data, usage data, dtcs, log files</li>
                <li>
                  Examples of methods: physical connection data (like OBD or FMS), Wi-Fi, Bluetooth, mobile network
                </li>
              </ul>
              <b>Examples of Out-of-Scope</b>
              <ul>
                <li>Data that is stored and used in the vehicle that is not offboarded in any way at any time</li>
              </ul>
            </>
          }
        />
        <Condition
          name="produced_through_usage"
          values={values}
          editing={editing || false}
          title="Usage of Product"
          description="The values of the variable/signal change during product usage"
          tooltip={
            <>
              <p className="tds-detail-05 tds-u-m0 tooltip-paragraph">
                Any variable/signal produced or changed through usage of the connected product. Even a variable/signal
                that is changed as the result of a stationary or turned-off product is considered. Only if data is
                defined by the manufacturer, is static, and not changed by the usage of the product is out of scope.
              </p>
              <br />
              <b>Examples of In-Scope</b>
              <ul>
                <li>Trend data</li>
                <li>Sensor data</li>
                <li>Sensor fusion data</li>
                <li>Derived data</li>
                <li>Hardware or software error codes</li>
                <li>Log files</li>
                <li>Environmental data</li>
                <li>HMI interactions</li>
              </ul>
              <b>Examples of Out-of-Scope</b>
              <br />
              <ul>
                <li>VIN</li>
                <li>Part number</li>
                <li>ECU hardware and software configurations</li>
                <li>Factory calibration values</li>
              </ul>
            </>
          }
        />
        <Condition
          name="enriched"
          values={values}
          editing={editing || false}
          title="Enrichment"
          description="Variable/Signal that has been produced using very unique and differentiating algorithms to derive new information. Across Traton, no more than 10% of all variables/signals are expected to be classified as enriched."
          tooltip={
            <>
              <p className="tds-detail-05 tds-u-m0 tooltip-paragraph">
                The Variable/Signal is produced from proprietary algorithms that are very unique, differentiating, and
                non public. These algorithms, often developed with significant investment, derive new values or insights
                from data that is hard to replicate due to the exclusive nature of the algorithms. Across Traton, no
                more than 10% of all variables/signals are expected to be classified as enriched.
              </p>
              <br />
              <b>Examples of In-Scope</b>
              <ul>
                <li>
                  Raw / sensor data or data modified via simple and openly available methods (e.g.,
                  addition/multiplication, max/min)
                </li>
                <li>Data derived from multiple sensors or sensor fusion</li>
                <li>
                  Data indicating hardware status and malfunctions
                  <ul>
                    <li>Example: Sensor data, temperature, pressure, fluid level, speed, acceleration, error codes</li>
                  </ul>
                </li>
              </ul>
              <b>Examples of Out-of-Scope</b>
              <br />
              <ul>
                <li>
                  Complex algorithms (e.g., AI, complex statistical functions) are applied to derive information from
                  existing data
                </li>
                <li>The applied algorithms/formulas are not publicly accessible</li>

                <li>
                  Not easily reproducible, significantly unique algorithms and methods
                  <ul>
                    <li>Example: Driving behavior, consumption estimation based on outside temperature, ETA</li>
                  </ul>
                </li>
              </ul>
            </>
          }
        />
        <Condition
          name="impact_on_safety"
          values={values}
          editing={editing || false}
          title="Impacts health, safety or security of natural persons"
          description="Variable/Signal leading to serious impacts on the health, security or safety of individuals"
          tooltip={
            <>
              <p className="tds-detail-05 tds-u-m0 tooltip-paragraph">
                Variable/Signal leading to serious impacts on the health, security or safety of individuals.
              </p>
              <br />
              <b>Examples of In-Scope</b>
              <ul>
                <li>All other data signals</li>
              </ul>
              <b>Examples of Out-of-Scope</b>
              <br />
              <br />
              Cryptographic keys and secrets:
              <ul>
                <li>API keys</li>
                <li>IP addresses</li>
                <li>Passwords</li>
                <li>Certificates</li>
                <li>Security Events (SCANIA naming?)</li>
              </ul>
            </>
          }
        />
        <div className="tds-row" style={{ marginLeft: "-10px", paddingTop: "10px" }}>
          <div className="tds-col-sm-12 medium_emphasis" style={{ paddingLeft: 0 }}>
            <TdsIcon
              className={`tds-text-blue-500 tds-u-ml1`}
              name="info"
              size="18px"
              style={{ paddingRight: 8 }}
            ></TdsIcon>
            Dear signal/variable owner, if you believe that a variable/signal should be classified as a trade secret,
            please contact XXX.
          </div>
        </div>

        <div className="tds-row" style={{ paddingTop: 20 }}>
          <div className="tds-col-sm-12">
            <b>In scope of EU Data Act</b>
            <TdsTooltip
              placement="right-start"
              selector={`.eudataact .in-scope-of-data-act-info-icon`}
              mouseOverTooltip
            ></TdsTooltip>
            <TdsIcon
              className={`tds-text-blue-500 tds-u-ml1 in-scope-of-data-act-info-icon`}
              name="info"
              size="18px"
            ></TdsIcon>
            {is_in_scope_for_data_act ? (
              <>
                <span style={{ paddingLeft: "10ex" }}>Yes</span>
                <div className="circle green">
                  <TdsIcon name="tick" size="18px"></TdsIcon>
                </div>
              </>
            ) : (
              <>
                <span style={{ paddingLeft: "10ex" }}>No</span>
                <div className="circle red">
                  <TdsIcon name="cross" size="18px"></TdsIcon>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(EUDataAct);
