import { TdsModal, TdsButton, TdsIcon } from '@scania/tegel-react';
import React, { ReactNode } from 'react';
import { forwardRef } from 'react';

interface ConfirmationDialogProps {
  onCancel: () => void;
  onConfirm: () => void;
  header: string;
  children?: ReactNode
  confirmOnly?: boolean
  confirmButtonTitle?: string
}

const ConfirmationDialog = forwardRef<HTMLTdsModalElement, ConfirmationDialogProps>(({ onCancel, onConfirm, header, children, confirmOnly, confirmButtonTitle }: ConfirmationDialogProps, inputRef) => {

  return (
    <TdsModal
      header={header}
      selector=".confirm-dialog"
      id="confirm-dialog"
      size="sm"
      actions-position="static"
      ref={inputRef}
      show
      prevent
      closable={false}
      onTdsClose={onCancel}
    >
      <span slot="body">
        {children ? children : (
          <>
            <p className="tds-body-01"><TdsIcon name='warning' size='40' />Are you sure you want to change the variable type.</p>
            <p className="tds-body-02">
            <strong>NOTE :</strong> Fields in the form will be reset except for{" "}
            <ul>
            <li><strong>Minimum CDC version</strong></li>
              <li><strong>Variable name</strong></li>
              {/* <li><strong>Current status name</strong></li> */}
              {/* <li><strong>RFMS name</strong></li> */}
              <li><strong>Description</strong></li>
              <li><strong>unit</strong></li>
            </ul>
            </p>
          </>
        )}
      </span>
      <span slot="actions" className="tds-u-flex tds-u-gap2">
        {confirmOnly !== true && <TdsButton variant="secondary" text="Cancel " size="sm" onClick={onCancel} />}
        <TdsButton type="button" variant="primary" text={confirmButtonTitle || "Confirm"} size="sm" onClick={onConfirm} />
      </span>
    </TdsModal>
  );
});

export default ConfirmationDialog