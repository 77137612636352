import React from "react"
import { TdsTextField, TdsIcon, TdsTooltip } from "@scania/tegel-react"
import { useMobXStore } from "../../hooks/useMobXStore"
import { observer } from "mobx-react"
import TextField from "../TextField/TextField"
import TextArea from "../TextArea/TextArea"
import EUDataAct from "../EUDataAct/EUDataAct"
import { useFormState } from "react-final-form";
import GenericDropdown from "../GenericDropdown/GenericDropdown"
import { connectivityPlatformDropdownList } from "../../helpers/genericDropdownList"

// type BaseVariableFieldsProps = {
// }

const BaseEditComponent = () => {
    const { variableDetailsStore } = useMobXStore()
    const { selectedVariableDetails } = variableDetailsStore;
    const { values } = useFormState<VariableBase>();
    //   useEffect(() => {
    //       const selectedType = getValues("type") as VariableType;
    //       setValue('scope', scopeValues[selectedType]);
    //       if (mode !== 'create') setValue('id', selectedVariableDetails?.id);
    //       // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [mode]);

    //   useEffect(() => {
    //       if (["create"].includes(mode)) {
    //           setValue("scope", scopeValues[values.type as VariableType])
    //       }
    //       // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [values.type])

    return <>
        <div className='tds-row'>
            <div className="tds-col-sm-3">
                <TdsTextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    label="Variable type *"
                    labelPosition="outside"
                    readOnly={true}
                    value={selectedVariableDetails?.type} >
                </TdsTextField>
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-9">
                <div className="tds-u-label tds-u-flex tds-u-align-items-center">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Variable name * </div></div>
                    <TdsIcon id="base-edit-variable-name" className="tds-text-blue-500 tds-u-ml1" name="info" size="18px"></TdsIcon>
                    <TdsTooltip placement="right-start" selector={`#base-edit-variable-name`} mouseOverTooltip={true}
                        text={`Needs to be the character for character same as in protobuf definition file or in ECU definition file. 
                
                                            The name needs to fully reflect the nested structure within the message. List elements need to be tagged with "[]". 
                
                                            e.g., measure[].speed.value`}
                        className="multiline-tooltip"
                    >
                    </TdsTooltip>
                </div>
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter a name "
                    readOnly={true}
                    dataFieldName="name"
                    required={true}
                />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-9">
                <div className="tds-u-label tds-u-flex tds-u-align-items-center">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Variable alias</div></div>
                    <TdsIcon id="base-edit-variable-alias" className="tds-text-blue-500 tds-u-ml1" name="info" size="18px"></TdsIcon>
                    <TdsTooltip placement="right-start" selector={`#base-edit-variable-alias`} mouseOverTooltip={true}
                        text="Business name of the variable (e.g., speed)"
                    >
                    </TdsTooltip>
                </div>
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter a name "
                    dataFieldName="variable_alias"
                />
            </div>
        </div>
        {/* Comment out Scania fields temporarily for MAN pilot view */}
        {/* <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter current status name for the variable"
                    label="Current status name"
                    labelPosition="outside"
                    dataFieldName="current_status_name"
                />
            </div>
            <div className="tds-col-sm-6">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter RFMS name for the variable"
                    label="RFMS name"
                    labelPosition="outside"
                    dataFieldName="rfms_name" />
            </div>
        </div> */}
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">
                <div className="tds-u-label tds-u-flex tds-u-align-items-center">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Business definition * </div></div>
                    <TdsIcon id="base-edit-business-definition" className="tds-text-blue-500 tds-u-ml1" name="info" size="18px"></TdsIcon>
                    <TdsTooltip placement="right-start" selector={`#base-edit-business-definition`} mouseOverTooltip={true} text="This definition, if the variable is in scope for EU data act, could be given to customers and third parties">
                    </TdsTooltip>
                </div>
                <div className="textarea-wrapper">
                    <TextArea
                        dataFieldName="description"
                        state="default"
                        rows={1}
                        modeVariant="secondary"
                        labelPosition="outside"
                    />
                    <div className="textarea-footer">
                        <span>A definition so that a business user and our customers can understand this variable.</span>
                        <span>{values.description?.length || 0} / {256}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">
                <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Technical definition</div></div>
                <div className="textarea-wrapper">
                    <TextArea
                        dataFieldName="technical_description"
                        state="default"
                        rows={1}
                        modeVariant="secondary"
                        labelPosition="outside"
                    />
                    <div className="textarea-footer">
                        <span>A definition for internal use, can get technical where necessary.</span>
                        <span>{values.technical_description?.length || 0} / {256}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
            <div className="tds-u-flex">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Variable team owner * </div></div>
                    <TdsIcon id="base-edit-variable-team-owner" className="tds-text-blue-500 tds-u-ml1" name="info" size="18px"></TdsIcon>
                    <TdsTooltip placement="right-start" selector={`#base-edit-variable-team-owner`} mouseOverTooltip={true} text="Can be your department name, your team name, or your VCT name">
                    </TdsTooltip>
                </div>
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="The responsible team name"
                    dataFieldName="signal_owner"
                />
            </div>
            <div className="tds-col-sm-6">
            <div className="tds-u-flex">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Unit *</div></div>
                </div>
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    dataFieldName="unit" />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
                <TextField
                    type="number"
                    size="md"
                    modeVariant="secondary"
                    label="CID code *"
                    labelPosition="outside"
                    dataFieldName="content_id"
                />
            </div>
            <div className="tds-col-sm-6">
                <GenericDropdown
                    className="connectivity-platform-dropdown"
                    label="Connectivity platform *"
                    labelPosition="outside"
                    size="md"
                    modeVariant="secondary"
                    openDirection="down"
                    listData={connectivityPlatformDropdownList}
                    dataFieldName="connectivity_platform"
                />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-8">
                <EUDataAct editing={true} values={values} />
            </div>
        </div>
    </>
}

export default observer(BaseEditComponent)