import { AnyObject } from "final-form";
import _ from "lodash";

export const newVariantVersion: VariableVariantVersionDetail = {
  version_number: undefined,
  chain: [{ type: "input", source: "" }] as ChainDataType,
  status: '' as VariableStatusType,
  persist: undefined,
  boundaries: '',
  unit: '',
  minimum_cdc_version: '',
  value_names: [{ hexCode: "", name: "" }],
  canlog: undefined,
  condition: null,
  modifiedBy: '',
  should_remove_redundant_samples: undefined,
  max_samples: undefined,
  sample_interval_ms: undefined,
  mode: '',
  value_type: undefined,
  created: undefined,
  verified: undefined,
  published: undefined,
  deprecated: undefined
};

export const newBaseVariant: VariantBase = {
  variant_name: 'Default',
  variant_versions: [newVariantVersion]
}

export const newBaseVariable: VariableBase = {
  type: 'gauge',
  name: '',
  version: '1.0',
  description: '',
  offboarded: true,
  produced_through_usage: true,
  enriched: false,
  impact_on_safety: false,
  //  {/* TODO: This is for temparary to remove varient data: Need to be implement later */}
  // variants: [newBaseVariant]
}


export enum ChainItemTypeEnum {
  AbsoluteCount = "absolute_count",
  Accumulating = "accumulating",
  AccumulatingSelf = "accumulating_self",
  AccumulatingTime = "accumulating_time",
  Acking = "acking",
  Age = "age",
  Comparator = "comparator",
  Differential = "differential",
  Dyadic = "dyadic",
  Filter = "filter",
  Index = "index",
  Input = "input",
  LowPass = "lowpass",
  Map = "map",
  Not = "not",
  Polynomial = "polynomial",
  Signals = "Signals",
  TrailerIdentification = "trailer_identification",
  TypeCast = "type_cast",
  WindowedAverage = "windowed_average",
  Work = "work"
}

export const transformToUIFriendlyData = (variableData: VariableBase) => {

  if (variableData.variants) {
    variableData.variant_name = variableData.variants[0].variant_name;
    variableData.variant_version = variableData.variants[0].variant_versions[0];
  }

  if (variableData.variant_version) {
    // Transform Value Names Object to Array
    let valueNamesArray: Array<ValueNamesObjectType> = [];
    const valueNames = variableData.variant_version.value_names as AnyObject;
    if (variableData && valueNames) {
      Object.keys(valueNames).forEach((key: string) => {
        valueNamesArray.push({ hexCode: key, name: valueNames[key] })
      })
    }
    variableData.variant_version.value_names = valueNamesArray;

    // Mark any Attributes retuned null to undefined as null is not undefined in the Eyes of Tegel TdsTextField
    // also update the chain item
    Object.keys(variableData.variant_version).forEach((key) => {
      if (key === "chain") {
        variableData.variant_version?.chain?.forEach((element: ChainItemType) => {
          Object.keys(element).forEach((chainKey: string) => {
            if (element[chainKey] === null) {
              element[chainKey] = undefined
            }
            if (chainKey.toLowerCase() === 'persist') {
              element[chainKey] = element[chainKey] ? '1' : '0'
            }
          })
        });
      } else if (variableData.variant_version && key.toLowerCase() === 'persist') {
        variableData.variant_version[key] = variableData.variant_version[key] ? '1' : '0'
      } else if (variableData.variant_version && variableData[key] === null) {
        variableData.variant_version[key] = undefined
      }
    });
  }
  return variableData;
}


const preparingBoundaries = (input: string): number[] => {
  if (!input) {
    return []
  }
  return input.split(',')
    .map(value => value.trim())
    .filter(value => value !== '')
    .map(value => parseFloat(value));
}


// TODO: add transformation for variant abd version
export const transformToAPIFriendlyData = (variableData: any) => {
  delete variableData["created"];
  if (variableData.value_names) {
    variableData.value_names = _.chain(variableData.value_names)
      .keyBy('hexCode')
      .mapValues('name')
      .value();
  }
  Object.keys(variableData).forEach((key) => {
    if (key === "chain") {
      variableData.chain?.forEach((element: ChainItemType) => {
        Object.keys(element).forEach((chainKey: string) => {
          if (chainKey.toLowerCase() === 'persist') {
            if (element[chainKey] !== undefined) {
              element[chainKey] = element[chainKey] === "1" ? true : false
            }
          }
        })
      });
    } else if (key.toLowerCase() === 'persist') {
      if (variableData[key] !== undefined) {
        variableData[key] = variableData[key] === '1' ? true : false
      }
    } else if (key.toLowerCase() === 'boundaries') {
      variableData[key] = preparingBoundaries(variableData.boundaries)
    } else if (['max_samples', 'sample_interval_ms'].includes(key.toLowerCase())) {
      variableData[key] = parseInt(variableData.max_samples, 10)
    }
  });
  return variableData;
}