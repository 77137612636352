import React from "react";
import SearchBar from "./SearchBar/SearchBar";
import { TdsIcon, TdsTable, TdsTableBody, TdsTooltip, TdsTableHeader } from "@scania/tegel-react";
import VariableListRow from "./VariableListRow/VariableListRow";
import "./VariableList.scss"
import Spinner from "../Spinner/Spinner";

type VariableListProps = {
    tableData: Array<VariableInfo>,
    setSearchId: (value: string) => void,
    setSearchName: (value: string) => void,
    setSearchVersion: (value: string) => void,
    handleRowClick:  (isBase: boolean, id?:number, variant?: any ) => void
    isLoadingVariableList: boolean,
}

const VariableList = ({ tableData, setSearchId, setSearchName, setSearchVersion, handleRowClick, isLoadingVariableList }: VariableListProps) => {

    return <>

        <div className="variableListHeader">
            <div className="row1">
                <div className="column1">
                    <section>
                        {"Variable name"}
                        <TdsTooltip placement="right-start" selector={`#variable-info`} mouseOverTooltip={true} text={`Supports multiple comma separated keywords and sub-strings`} >
                            <p><b>E.g.</b> : cleaning, charging, battery</p>
                        </TdsTooltip>
                        <span slot="label" id={`variable-info`} >
                            <TdsIcon className="tds-text-blue-500 tds-u-ml1" name="support" size="18px"></TdsIcon>
                        </span>
                    </section>
                </div>
                <div className="column2 fixedIcon">
                    <button id="filter-variable-list">
                        <TdsIcon name="filters" size="30px" />
                    </button>
                </div>
            </div>

            <div className="row2">{<SearchBar setSearchedValue={setSearchName} />}</div>

        </div>
        <div className="variableListData">
            {isLoadingVariableList && <Spinner />}
            <TdsTable
                tableId="expandable-table"
                noMinWidth
                responsive
                expandableRows
            >
                <TdsTableHeader style={{display: 'none'}}/>
                <TdsTableBody>
                        {tableData && tableData.length > 0 && tableData.map((row: any, index: number) => (
                            <VariableListRow
                                index={index}
                                id={row.id}
                                showDetails={handleRowClick}
                                key={row.id}
                                name={row.name}
                                type={row.type}
                                variants={row.variants}
                            />
                        ))}
                </TdsTableBody>
            </TdsTable>
        </div>

    </>
}

export default VariableList;