/* eslint-disable @typescript-eslint/no-unused-vars */
import { TdsBanner } from "@scania/tegel-react";
import { ValidationErrors } from "final-form";
import arrayMutators from 'final-form-arrays';
import _ from "lodash";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
// import ConfirmationDialog from "../../components/VariableDetails/ConfirmationDialog/ConfirmationDialog";
import { newBaseVariable } from "../../helpers/dataHelper";
import validateVariable, { validateRequiredAttributes } from "../../helpers/validationHelper";
import { useMobXStore } from "../../hooks/useMobXStore";

//Styles
import "./BaseVariableEdit.scss";
import BaseEditComponent from "../../components/BaseVariable/BaseEditComponent";
import TopSection from "../../components/BaseVariable/TopSection/TopSection";
import Spinner from "../../components/Spinner/Spinner";


const BaseVariableEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  // const { reset, setValue } = methods;
  const { variableBaseStore } = useMobXStore()
  const { selectedVariable, selectedVariableBase, isLoadingVariableDetails, isSaveSuccessfull, variableFetchError } = variableBaseStore

  const [variableStatus, setVariableStatus] = useState<string>('');
  const [validVariableData, setValidVariableData] = useState<VariableBase>();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const clearSelectedVariable = () => {
    variableBaseStore.clearSelectVariable();
  }

  const handleCreateVariableDetails = (variableDetails: VariableBase) => {
    variableBaseStore.resetSaveSuccessFlag();
    variableBaseStore.createVariableDetails(variableDetails);
  };

  const handleUpdateVariableDetails = (variableDetails: VariableBase) => {
    variableBaseStore.resetSaveSuccessFlag();
    if (variableDetails.id) {
      variableBaseStore.updateVariableDetails(variableDetails.id, variableDetails);
    }
  }

  const onSubmit = (data: VariableBase) => {
    if (data.type === 'class') {
      if (data.boundaries && data.boundaries instanceof String) {
        data.boundaries = data.boundaries.split(',').map((item: string) => parseFloat(item.trim()));
      }
    }
    setValidVariableData(data);
    if (!['Created', 'Save'].includes(variableStatus)) {
      setShowConfirmationDialog(true);
    } else {
      executeUserAction(variableStatus, data);
    }
    //executeUserAction(variableStatus, validVariableData);
  };

  const detectUserAction = (userAction: string) => {
    setVariableStatus(userAction)
  };

  const executeUserAction = (variableStatus: string, data: VariableBase) => {
    if ((variableStatus === 'Created' && data?.name && data?.id === undefined)) {
      const updatedVariableData = { ...data, status: variableStatus as VariableStatusType };
      handleCreateVariableDetails(updatedVariableData) // check and call the right method after integrating update flow
    }
    else if (variableStatus === 'Save') {
      // {/* TODO: This is for temparary to remove varient data: Need to be implement later */}
      const updatedVariableData = { ...data, status: variableStatus as VariableStatusType, variants: [] };
      handleUpdateVariableDetails(updatedVariableData)
    }
    else {
      const updatedVariableData = { ...data, status: variableStatus as VariableStatusType };
      handleUpdateVariableDetails(updatedVariableData)
    }
  }

  // const onCancel = () => {
  //   setShowConfirmationDialog(false);
  // }

  // const handleConfirmationStatuschange = () => {
  //   validVariableData && executeUserAction(variableStatus, validVariableData);
  //   setShowConfirmationDialog(false);
  // }

  useEffect(() => {

    if (id) {
      const idParam = parseFloat(id);
      if (idParam !== variableBaseStore.selectedVariable) {
        variableBaseStore.selectVariableForViewing(idParam);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [id]);

  // todo: There are no more chain elements in base variable object 
  // useEffect(() => {
  //   //Reset the form with new default values whenever selectedVariableDetails changes
  //   // initialize(selectedVariableDetails);
  //   if (!selectedVariableBase.chain || selectedVariableBase.chain.length === 0) {
  //     // setValue("chain", [{ type: "input" } as ChainItemType])
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps 
  // }, [selectedVariableBase]);

  //todo: The status of the base variable doesn't matter.
  useEffect(() => {
    /*ToDo: enable condition for variants to be not able to edit via Base */
    // if (selectedVariable === selectedVariableDetails.id && selectedVariableDetails.variant ) {

    //   navigate(`/${selectedVariable}/view`)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedVariableBase, navigate, selectedVariable]);

  useEffect(() => {
    if (isSaveSuccessfull) {
      variableBaseStore.resetSaveSuccessFlag();
      navigate(`/base/${selectedVariable}/view`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveSuccessfull]);

  const onFormValidate = (values: Record<string, any>) => {
    const validationErrors: ValidationErrors = {};
    const mustHaveFileds = ["type", "name"]
    if (!["Created", 'Save'].includes(variableStatus) &&
      (selectedVariableBase.status && !["", "Created"].includes(selectedVariableBase.status))) {
      //validate Values here 
      const invalidFields = validateVariable(values as VariableBase);
      invalidFields.forEach((fieldName) => {
        validationErrors[fieldName] = "This field is required!"
      })
    } else {
      const invalidFields = validateRequiredAttributes(values as VariableBase, mustHaveFileds);
      invalidFields.forEach((fieldName) => {
        validationErrors[fieldName] = `Variable ${fieldName.toLowerCase()} is required!`
      })
    }

    return validationErrors
  }
  return (
    <>
      {!isLoadingVariableDetails && variableFetchError !== "" &&
        <TdsBanner variant="error" header={variableFetchError} subheader={`The variable with Id: ${selectedVariable} not found in the database.`}>
        </TdsBanner>
      }
      {(!isLoadingVariableDetails && variableFetchError === "" && selectedVariable >= 0) &&
        <Form
          mutators={{
            // potentially other mutators could be merged here
            ...arrayMutators
          }}
          subscription={{ submitting: true, pristine: true, invalid: true }}
          onSubmit={onSubmit}
          validate={onFormValidate}
          initialValues={selectedVariableBase || newBaseVariable}
          keepDirtyOnReinitialize={false}
          render={({ handleSubmit, hasValidationErrors, errors }) => {
            return <form onSubmit={handleSubmit}>
              {hasValidationErrors && errors && <div>
                {_.keys(errors).map((key: string) => {
                  return <span>{errors[key]}</span>
                })}
              </div>}
              {isLoadingVariableDetails ? <Spinner /> :
                <>
                  <TopSection
                    selectedVariableId={selectedVariable}
                    selectedVariableDetails={selectedVariableBase}
                    clearSelectedVariable={clearSelectedVariable}
                    onUserAction={detectUserAction} />
                  <div className={`tds-container-fluid tds-background-grey-50 tds-u-p1 tds-u-pt2 tds-u-pb3 form-container read`}>
                    <BaseEditComponent />
                  </div>
                </>
              }
            </form>
          }} />
      }
      {/* {showConfirmationDialog &&
        <ConfirmationDialog
          header="Confirm Variable status change"
          onCancel={onCancel}
          onConfirm={handleConfirmationStatuschange}
        >
          <>
            <p className="tds-body-01">Are you sure you want to change the status of the variable to <strong>{variableStatus}</strong>.</p>
            <p className="tds-body-02">
              <strong>NOTE </strong>: Once confirmed, the status could not be reverted back
            </p>
          </>
        </ConfirmationDialog>
      } */}
    </>
  );
};

export default observer(BaseVariableEdit);
