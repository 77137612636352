import React, { useEffect } from "react";
import VariablesFilterModal from '../../components/VariablesList/VariablesFilterModal/VariablesFilterModal'

import { useMobXStore } from "../../hooks/useMobXStore";
import VariableList from "../../components/VariablesList/VariableList";
//Styles
import "./Home.scss";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

const HomePage = () => {

  const { variableListStore, variableDetailsStore } = useMobXStore();
  const tableData = variableListStore.sortedFilteredTableData;
  const isLoadingVariableList = variableListStore.isLoadingVariableList;
  const navigate = useNavigate();


  
    const { selectVariableVariantVersionForViewing } = variableDetailsStore

  const handleRowClick = (isBase:boolean, id?:number, variant?: any ) => {
   
    if(!isBase){
      selectVariableVariantVersionForViewing(variant?.id, variant?.variant_name, variant?.version)
      navigate(`/${variant.id}/view`);
    }
    else
      navigate(`/base/${id}/view`);
   
  };

  const setSearchId = (value: string) => {
    variableListStore.setSearchId(value);
  }

  const setSearchName = (value: string) => {
    variableListStore.setSearchName(value);
  }

  const setSearchVersion = (value: string) => {
    variableListStore.setSearchVersion(value);
  }

  useEffect(() => {
    variableListStore.loadVariables();
  }, [variableListStore])

  return <>
    <div className="content left">
      <VariableList
        setSearchId={setSearchId}
        setSearchName={setSearchName}
        handleRowClick={handleRowClick}
        setSearchVersion={setSearchVersion}
        tableData={tableData}
        isLoadingVariableList={isLoadingVariableList}
      />
      <VariablesFilterModal store={variableListStore} />
    </div>
    <div className="content right container">
      <Outlet />

    </div>
  </>
}

export default observer(HomePage)
