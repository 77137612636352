import React, { useEffect, useState } from "react"
import { TdsTextField, TdsTooltip, TdsIcon } from "@scania/tegel-react"
import "./GenericFields.scss"
import { scopeValues, variableTypesList, connectivityPlatformDropdownList } from "../../../helpers/genericDropdownList"
import { useForm, useFormState } from "react-final-form"
import { getDisplayValueForTextFields } from "../../../helpers/getDisplayValue"
import { isNotUpdateable } from "../../../helpers/isNotUpdateable"
import { observer } from "mobx-react"
import GenericDropdown from "../../GenericDropdown/GenericDropdown"
import TextField from "../../TextField/TextField"
import TextArea from "../../TextArea/TextArea"
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog"
import EUDataAct from "../../EUDataAct/EUDataAct"

type GenericFieldsProps = {
    selectedVariableBaseDetails?: VariableBase
    mode: string
}

const GenericFields = ({ selectedVariableBaseDetails, mode }: GenericFieldsProps) => {
    const { initialValues, values } = useFormState();
    const { reset, resetFieldState } = useForm();
    const form = useForm();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [oldVariableTypeValue, setOldVariableTypeValue] = useState<VariableType>('gauge');

    const getValues = (dataFieldname: string) => {
        return values[dataFieldname];
    }
    const setValue = (dataFieldname: string, value: any) => {
        form.change(dataFieldname, value)
    }

    const changeVariableTypeAndResetForm = (oldValue: string) => {
        setOldVariableTypeValue(oldValue as VariableType);
        setShowConfirmationDialog(true);
    }

    const onCancel = () => {
        setValue('type', oldVariableTypeValue);
        setShowConfirmationDialog(false);
    }

    const handleConfirmationStatuschange = () => {

        // Resetting the values except generic form fields and any validation messages we have.
        const newFormValues = values;
        Object.keys(newFormValues).forEach((key: string) => {
            if (!['current_status_name', 'in_scope', 'name', 'description', 'rfms_name', 'type', 'scope'].includes(key)) {
                newFormValues[key] = initialValues[key]
            } else if (['current_status_name', 'in_scope', 'name', 'description', 'rfms_name', 'type'].includes(key)) {
                resetFieldState(key);
            }
            if (key === 'chain') {
                newFormValues.chain.forEach((item: any, index: any) => {
                    resetFieldState(`chain[${index}].source`);
                });
            }

        });
        reset(newFormValues);
        setShowConfirmationDialog(false);
    }

    useEffect(() => {
        const selectedType = getValues("type") as VariableType;
        setValue('scope', scopeValues[selectedType]);
        if (mode !== 'create') setValue('id', selectedVariableBaseDetails?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    useEffect(() => {
        if (["create"].includes(mode)) {
            setValue("scope", scopeValues[values.type as VariableType])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.type])

    return <>
        <div className='tds-row'>
            <div className="tds-col-sm-6">
                {(isNotUpdateable(mode, 'Variable type')) ?
                    <TdsTextField
                        type="text"
                        size="md"
                        state="default"
                        modeVariant="secondary"
                        label="Variable type *"
                        labelPosition="outside"
                        readOnly={true}
                        value={getDisplayValueForTextFields(selectedVariableBaseDetails?.type, mode)}                    >
                    </TdsTextField>
                    :
                    <GenericDropdown
                        className="variable-type-dropdown"
                        label="Variable type *"
                        labelPosition="outside"
                        placeholder="Select type"
                        size="md"
                        modeVariant="secondary"
                        openDirection="down"
                        listData={variableTypesList}
                        dataFieldName="type"
                        onDropdownChange={changeVariableTypeAndResetForm}
                    />
                }
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-9">
                <div className="tds-u-label tds-u-flex tds-u-align-items-center">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Variable name * </div></div>
                    <TdsIcon id="generic-fields-variable-name" className="tds-text-blue-500 tds-u-ml1" name="info" size="18px"></TdsIcon>
                    <TdsTooltip placement="right-start" selector={`#generic-fields-variable-name`} mouseOverTooltip={true}
                        text={`Needs to be the character for character same as in protobuf definition file or in ECU definition file. 

                            The name needs to fully reflect the nested structure within the message. List elements need to be tagged with "[]". 

                            e.g., measure[].speed.value`}
                        className="multiline-tooltip"
                    >
                    </TdsTooltip>
                </div>
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter a name "
                    readOnly={isNotUpdateable(mode, 'Variable name')}
                    dataFieldName="name"
                    required={true}
                />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-9">
                <div className="tds-u-label tds-u-flex tds-u-align-items-center">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Variable alias</div></div>
                    <TdsIcon id="generic-fields-variable-alias" className="tds-text-blue-500 tds-u-ml1" name="info" size="18px"></TdsIcon>
                    <TdsTooltip placement="right-start" selector={`#generic-fields-variable-alias`} mouseOverTooltip={true}
                        text="Business name of the variable (e.g., speed)"
                    >
                    </TdsTooltip>
                </div>
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter a name "
                    readOnly={isNotUpdateable(mode, 'Variable alias')}
                    dataFieldName="variable_alias"
                />
            </div>
        </div>
        {/* Comment out Scania fields temporarily for MAN pilot view */}
        {/* <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter current status name for the variable"
                    label="Current status name"
                    labelPosition="outside"
                    readOnly={isNotUpdateable(mode, 'Current status name')}
                    dataFieldName="current_status_name"
                />
            </div>
            <div className="tds-col-sm-6">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter RFMS name for the variable"
                    label="RFMS name"
                    labelPosition="outside"
                    readOnly={isNotUpdateable(mode, 'RFMS name')}
                    dataFieldName="rfms_name" />
            </div>
        </div> */}
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">
                <div className="tds-u-label tds-u-flex tds-u-align-items-center">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Business definition * </div></div>
                    <TdsIcon id="generic-fields-business-definition" className="tds-text-blue-500 tds-u-ml1" name="info" size="18px"></TdsIcon>
                    <TdsTooltip placement="right-start" selector={`#generic-fields-business-definition`} mouseOverTooltip={true} text="This definition, if the variable is in scope for EU data act, could be given to customers and third parties">
                    </TdsTooltip>
                </div>
                <div className="textarea-wrapper">
                    <TextArea
                        dataFieldName="description"
                        state="default"
                        rows={1}
                        modeVariant="secondary"
                        labelPosition="outside"
                        readOnly={isNotUpdateable(mode, 'Business definition')}
                    />
                    <div className="textarea-footer">
                        <span>A definition so that a business user and our customers can understand this variable.</span>
                        <span>{values.description?.length || 0} / {256}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">
                <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Technical definition</div></div>
                <div className="textarea-wrapper">
                    <TextArea
                        dataFieldName="technical_description"
                        state="default"
                        rows={1}
                        modeVariant="secondary"
                        labelPosition="outside"
                        readOnly={isNotUpdateable(mode, 'Technical definition')}
                    />
                    <div className="textarea-footer">
                        <span>A definition for internal use, can get technical where necessary.</span>
                        <span>{values.technical_description?.length || 0} / {256}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
                <div className="tds-u-flex">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Variable team owner* </div></div>
                    <TdsIcon id="generic-fields-variable-team-owner" className="tds-text-blue-500 tds-u-ml1" name="info" size="18px"></TdsIcon>
                    <TdsTooltip placement="right-start" selector={`#generic-fields-variable-team-owner`} mouseOverTooltip={true} text="Can be your department name, your team name, or your VCT name">
                    </TdsTooltip>
                </div>
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="The responsible team name"
                    readOnly={isNotUpdateable(mode, 'Variable team owner')}
                    dataFieldName="signal_owner"
                />
            </div>
            <div className="tds-col-sm-6">
                <div className="tds-u-flex">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Unit</div></div>
                </div>
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    readOnly={isNotUpdateable(mode, 'Unit')}
                    dataFieldName="unit" />
            </div>
        </div>
        <div className='tds-row tds-u-mt3'>
            <div className="tds-col-sm-6">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    label="CID code*"
                    labelPosition="outside"
                    readOnly={isNotUpdateable(mode, 'CID code *')}
                    dataFieldName="content_id"
                />
            </div>
            <div className="tds-col-sm-6">
                <GenericDropdown
                    className="connectivity-platform-dropdown"
                    label="Connectivity platform *"
                    labelPosition="outside"
                    size="md"
                    modeVariant="secondary"
                    openDirection="down"
                    listData={connectivityPlatformDropdownList}
                    dataFieldName="connectivity_platform"
                />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">
                <EUDataAct values={values as VariableBase} editing={true} />
            </div>
        </div>
        {showConfirmationDialog &&
            <ConfirmationDialog
                header="Confirm Variable status change"
                onCancel={onCancel}
                onConfirm={handleConfirmationStatuschange}
            >
                <>
                    <p className="tds-body-01">Are you sure you want to change the  variable to type <strong>{values['type']}</strong>.</p>
                    <p className="tds-body-02">
                        <strong>NOTE </strong>: Once confirmed, the form values except for <strong>Variable name, Description & In scope of Data act </strong> will be reset.
                    </p>
                </>
            </ConfirmationDialog>
        }
    </>
}

export default observer(GenericFields)