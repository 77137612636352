import { useContext } from "react"
import { TdsIcon, TdsTextarea, TdsTooltip, TdsTextField } from "@scania/tegel-react"
import "./GenericFieldsView.scss"
import { UserActionContext } from "../../../AppContext"
import { getDisplayValueForTextFields } from "../../../helpers/getDisplayValue"
import { observer } from "mobx-react"
import EUDataAct from "../../EUDataAct/EUDataAct"

type GenericFieldsViewProps = {
    selectedVariableBaseDetails?: VariableBase
}

const GenericFieldsView = ({ selectedVariableBaseDetails }: GenericFieldsViewProps) => {
    const { action } = useContext(UserActionContext);

    return <>
        <div className='tds-row'>
            <div className="tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    label="Variable type *"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.type, action)}
                >
                </TdsTextField>
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-9">
                <div className="tds-u-label tds-u-flex tds-u-align-items-center">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Variable name * </div></div>
                    <TdsIcon id="generic-fields-view-variable-name" className="tds-text-blue-500 tds-u-ml1" name="info" size="18px"></TdsIcon>
                    <TdsTooltip placement="right-start" selector={`#generic-fields-view-variable-name`} mouseOverTooltip={true}
                        text={`Needs to be the character for character same as in protobuf definition file or in ECU definition file. 
                
                                            The name needs to fully reflect the nested structure within the message. List elements need to be tagged with "[]". 
                
                                            e.g., measure[].speed.value`}
                        className="multiline-tooltip"
                    >
                    </TdsTooltip>
                </div>
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter a name "
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.name, action)}
                />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-9">
                <div className="tds-u-label tds-u-flex tds-u-align-items-center">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Variable alias</div></div>
                    <TdsIcon id="generic-fields-view-variable-alias" className="tds-text-blue-500 tds-u-ml1" name="info" size="18px"></TdsIcon>
                    <TdsTooltip placement="right-start" selector={`#generic-fields-view-variable-alias`} mouseOverTooltip={true}
                        text="Business name of the variable (e.g., speed)"
                    >
                    </TdsTooltip>
                </div>
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter a name "
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.variable_alias, action)}
                    readOnly={true}
                />
            </div>
        </div>
        {/* Comment out Scania fields temporarily for MAN pilot view */}
        {/* <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter current status name for the variable"
                    label="Current status name"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.current_status_name, action)}
                />
            </div>
            <div className="tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter RFMS name for the variable"
                    label="RFMS name"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.rfms_name, action)} />
            </div>
        </div> */}
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">
                <div className="tds-u-label tds-u-flex tds-u-align-items-center">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Business definition * </div></div>
                    <TdsIcon id="generic-fields-view-business-definition" className="tds-text-blue-500 tds-u-ml1" name="info" size="18px"></TdsIcon>
                    <TdsTooltip placement="right-start" selector={`#generic-fields-view-business-definition`} mouseOverTooltip={true} text="This definition, if the variable is in scope for EU data act, could be given to customers and third parties">
                    </TdsTooltip>
                </div>
                <TdsTextarea
                    state="default"
                    rows={1}
                    modeVariant="secondary"
                    placeholder="A definition so that a business user and our customers can understand this variable."
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.description, action)}
                />
                <div className="textarea-footer">
                    <span>A definition so that a business user and our customers can understand this variable.</span>
                    <span>{selectedVariableBaseDetails?.description?.length || 0} / {256}</span>
                </div>
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">
                <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Technical definition</div></div>
                <div className="textarea-wrapper">
                    <TdsTextarea
                        state="default"
                        rows={1}
                        modeVariant="secondary"
                        value={getDisplayValueForTextFields(selectedVariableBaseDetails?.technical_description, action)}
                        readOnly={true}
                    />
                    <div className="textarea-footer">
                        <span>A definition for internal use, can get technical where necessary.</span>
                        <span>{selectedVariableBaseDetails?.technical_description?.length || 0} / {256}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
                <div className="tds-u-flex">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Variable team owner * </div></div>
                    <TdsIcon id="generic-fields-view-variable-team-owner" className="tds-text-blue-500 tds-u-ml1" name="info" size="18px"></TdsIcon>
                    <TdsTooltip placement="right-start" selector={`#generic-fields-view-variable-team-owner`} mouseOverTooltip={true} text="Can be your department name, your team name, or your VCT name">
                    </TdsTooltip>
                </div>
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="The responsible team name"
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.signal_owner, action)}
                    readOnly={true}
                />
            </div>
            <div className="tds-col-sm-6">
                <div className="tds-u-flex">
                    <div className="text-field-label-outside sc-tds-text-field"> <div className="sc-tds-text-field">Unit *</div></div>
                </div>
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.unit, action)}
                    readOnly={true}
                />
            </div>
        </div>
        <div className='tds-row tds-u-mt3'>
            <div className="tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    label="CID code *"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.content_id, action)}
                />
            </div>
            <div className="tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    label="Connectivity platform *"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.connectivity_platform, action)}
                >
                </TdsTextField>
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">
                <EUDataAct values={selectedVariableBaseDetails} />
            </div>
        </div>
    </>
}

export default observer(GenericFieldsView)