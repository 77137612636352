import { observable, action, makeObservable, runInAction } from "mobx"
import { createVariableData, updateVariableDataById } from "../adapters/VariableDetailsAdapter"
import {  getVariableDetailsById } from "../adapters/VariableBaseAdapter"
import { RootStoreType } from ".";

import { newBaseVariable, transformToAPIFriendlyData, transformToUIFriendlyData } from "../helpers/dataHelper";

const selectedVariableVariantVersionValues: SelectedVariableVariantVersion = {
  id: -1,
  variant_name: '',
  version_number: ''
}

export class VariableDetailsStore {
  selectedVariableVariantVersion: SelectedVariableVariantVersion = selectedVariableVariantVersionValues;
  selectedVariableDetails: VariableBase = newBaseVariable
  isLoadingVariableDetails: boolean = false
  error: string | null = null;
  toastData: ToastData | null = { message: "Test Message", variant: "error" };
  rootStore: RootStoreType | null = null;
  isSaveSuccessfull: boolean = false
  isVariableUpdateMode: boolean = false
  variableUpdateMode: string = ""
  variableFetchError: string = ""

  constructor(root: RootStoreType) {
    this.rootStore = root;
    return makeObservable(this, {
      selectedVariableVariantVersion: observable,
      selectedVariableDetails: observable,
      error: observable,
      isSaveSuccessfull: observable,
      variableUpdateMode: observable,
      isVariableUpdateMode: observable,
      toastData: observable,
      variableFetchError: observable,
      selectVariableVariantVersionForViewing: action,
      setSelectedVariableDetails: action,
      clearSelectVariable: action,
      fetchDetailsOfSelectedVariableVariantVersion: action,
      createVariableVariantVersionDetails: action,
      updateVariableVariantVersionDetails: action,
      activateCreateMode: action,
      setVariableUpdateMode: action,
      setIsVariableUpdateMode: action,
      resetSaveSuccessFlag: action
    })
  }

  selectVariableVariantVersionForViewing = (id: number, variant_name: string, version_number: string) => {
    if (id !== 0 && variant_name !== '' && version_number !== '') {
      this.selectedVariableVariantVersion = { id, variant_name, version_number };
      this.fetchDetailsOfSelectedVariableVariantVersion();
    }
    else {
      this.selectedVariableVariantVersion = { id, variant_name, version_number };
    }
  }

  setSelectedVariableDetails = (variableDetail: VariableBase) => {
    this.selectedVariableDetails = variableDetail;
  }

  clearSelectVariable = () => {
    this.selectedVariableVariantVersion = selectedVariableVariantVersionValues;
  }

  fetchDetailsOfSelectedVariableVariantVersion = async () => {
    if (!this.isLoadingVariableDetails) {
      this.isLoadingVariableDetails = true
      this.variableFetchError = "";
      try {

        /* we call the base variable endpoint here since the current variant version endpoint response doesnt include
        necessary base variable fields which we show in the UI view when user selects a variant version*/

        const response = await getVariableDetailsById(this.selectedVariableVariantVersion.id)
        if (response && response.data ) {
          response.data = transformToUIFriendlyData(response.data);
          runInAction(() => {
            this.selectedVariableDetails = response.data;
            //filter through the variants object in base variable response (this.selectedVariableDetails) and match user selected variant version (this.selectedVariableVariantVersion)
            this.selectedVariableDetails.variants?.forEach((variant)=>{     
              if(variant.variant_name === this.selectedVariableVariantVersion.variant_name)
                variant.variant_versions.forEach((version)=>{              
                  if(version.version_number === this.selectedVariableVariantVersion.version_number)
                    this.selectedVariableDetails.variant_version = version;
                })
            });           
            this.selectedVariableDetails.variant_name = this.selectedVariableVariantVersion.variant_name;
            this.isLoadingVariableDetails = false
          });
        }
      } catch (error: any) {
        console.error('variable detail store error when fetching variant:', error);
        this.isLoadingVariableDetails = false
        runInAction(() => {
          if (error.isAxiosError) {
            if (error.response?.status === 404) {
              this.variableFetchError = "Variable Not Found!"
            } else if (error.response.status === 500) {
              this.variableFetchError = "Something went wrong while retrieving variable details. Please try again or contact Variable Registry Support"
            }
          }
        });
      }
    }
  }

  createVariableVariantVersionDetails = async (requestPayload: any) => {
    this.isLoadingVariableDetails = true;
    try {
      // requestPayload = transformToAPIFriendlyData(requestPayload)
      if (requestPayload.content_id) {
        requestPayload.content_id = parseInt(requestPayload.content_id, 10);
      }

      const response = await createVariableData(requestPayload);
      runInAction(() => {
        this.variableUpdateMode = ""
        this.isLoadingVariableDetails = false;
        this.rootStore?.toastStore?.addToast("Created variable successfully", "success");
        this.selectedVariableVariantVersion = response.data
        this.isSaveSuccessfull = true;
        this.rootStore?.variableListStore.loadVariables();
        this.fetchDetailsOfSelectedVariableVariantVersion();
      });
    } catch (error: any) {
      console.error('variable detail store error when creating variant:', error);
      runInAction(() => {
        this.error = error.message;
        this.isLoadingVariableDetails = false;
        this.rootStore?.toastStore?.addToast("Create variable failed with message " + error.message, "error");
      });
    }
  }

  updateVariableVariantVersionDetails = async ({ id, variant_name, version_number }: SelectedVariableVariantVersion, requestPayload: any) => {
    this.isLoadingVariableDetails = true;
    try {
      requestPayload = transformToAPIFriendlyData(requestPayload)
      await updateVariableDataById({ id, variant_name, version_number }, requestPayload);
      runInAction(() => {
        this.variableUpdateMode = ""
        this.isLoadingVariableDetails = false;
        this.isSaveSuccessfull = true;
        this.rootStore?.toastStore?.addToast("Variable successfully updated.", "success");
        this.rootStore?.variableListStore.loadVariables();
        this.fetchDetailsOfSelectedVariableVariantVersion()
      });
    } catch (error: any) {
      console.error('variable detail store error when updating variant:', error);
      runInAction(() => {
        this.error = error.message;
        this.isLoadingVariableDetails = false;
        this.rootStore?.toastStore?.addToast("Variable updateing failed with message " + error.message, "error", 5);
      });
    }
  }

  activateCreateMode = () => {
    this.selectedVariableVariantVersion = selectedVariableVariantVersionValues;
    this.selectedVariableDetails = newBaseVariable;
  }

  setVariableUpdateMode = (newUpdateMode: string) => {
    this.variableUpdateMode = newUpdateMode;
  }

  setIsVariableUpdateMode = (value: boolean) => {
    this.isVariableUpdateMode = value;
  }
  resetSaveSuccessFlag = () => {
    this.isSaveSuccessfull = false
  }

}